import './About.scss';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

const About = (props: { className?: string; landingOpen: boolean }) => {
  const titleAnimation = useAnimation();
  const subtitleAnimation = useAnimation();

  useEffect(() => {
    titleAnimation.set({ opacity: 0, left: '-10%' });
    subtitleAnimation.set({ opacity: 0, transform: 'translateY(50%)' });
    if (props.landingOpen) {
      setTimeout(() => {
        titleAnimation.start({
          opacity: 1,
          left: '0%',
          transition: { duration: 1 },
        });
      }, 0.5 * 1000);
      setTimeout(() => {
        subtitleAnimation.start({
          opacity: 1,
          transform: 'translateY(0%)',
          transition: { duration: 1 },
        });
      }, 1.5 * 1000);
    }
  }, [props.landingOpen, titleAnimation, subtitleAnimation]);

  return (
    // style={{ backgroundImage: `url(${background})` }}
    <div className={`aboutContainer ${props.className}`}>
      <div className='aboutText'>
        <motion.h2 animate={titleAnimation}>There's Beauty In Everything</motion.h2>
        <motion.p animate={subtitleAnimation}>My name is Brian Smith. I am a 21 year old, independent filmmaker, based out of Bluffton, Indiana, with a passion for displaying beauty through videography, video editing, and animation.</motion.p>
      </div>
    </div>
  );
};

export default About;
