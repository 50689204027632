import './Contact.scss';
import Card from 'react-bootstrap/Card';
import { GrMail } from 'react-icons/gr';
import { IoPhonePortraitOutline } from 'react-icons/io5';
import contactBackground from '../assets/contact-video.mp4';

const Contact = (props: { className?: string }) => {
  return (
    <div id='contact' className={`${props.className}`}>
      <video id='myVideo' role='presentation' crossOrigin='anonymous' preload='auto' muted loop autoPlay src={contactBackground}></video>

      <div className='text'>
        <h2>Contact Me</h2>
        <div className='cardsContainer'>
          <Card>
            <div className='contactMethods'>
              <div className='contact'>
                <a href='mailto:buoyant.brian@gmail.com'>
                  <GrMail className='icon' />
                </a>
              </div>
              <div className='contact'>
                <a href='tel:260-827-7870'>
                  <IoPhonePortraitOutline className='icon' />
                </a>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Contact;
