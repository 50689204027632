import './TopBar.scss';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Nav } from 'react-bootstrap';
import logo from '../assets/logo.png';

const TopBar = (props: { landingOpen: boolean; page: number; setPage: React.Dispatch<React.SetStateAction<number>> }) => {
  return (
    <Navbar style={props.landingOpen ? { zIndex: '1' } : {}} id='nav'>
      <Container>
        <Navbar.Brand href='#home'>
          <img alt='' src={logo} width='30' height='30' className='d-inline-block align-top' />
        </Navbar.Brand>
        <Nav className='me-auto'>
          <Nav.Link className={`navLink ${props.page === 0 ? 'navSelected' : ''}`} onClick={() => props.setPage(0)}>
            About
          </Nav.Link>
          <Nav.Link className={`navLink ${props.page === 1 ? 'navSelected' : ''}`} onClick={() => props.setPage(1)}>
            Videos
          </Nav.Link>
          <Nav.Link className={`navLink ${props.page === 2 ? 'navSelected' : ''}`} onClick={() => props.setPage(2)}>
            Contact Me
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default TopBar;
