import React from 'react';
import './App.scss';

import ReactPageScroller from 'react-page-scroller';
import Landing from './Landing/Landing';
import About from './About/About';
import Videos from './Videos/Videos';
import Contact from './Contact/Contact';
import TopBar from './TopBar/TopBar';
import { BsArrowDown } from 'react-icons/bs';

function App() {
  const [page, setPage] = React.useState(0);

  const [landingOpen, setLandingOpen] = React.useState(false);

  if (!landingOpen) {
    setTimeout(() => {
      setLandingOpen(true);
    }, 2000);
  }

  return (
    <div className="App">
      <TopBar landingOpen={landingOpen} setPage={setPage} page={page} />
      <Landing isPageOpen={landingOpen}>
        <ReactPageScroller pageOnChange={setPage} customPageNumber={page}>
          <About landingOpen={landingOpen} className="navOffset" />
          <Videos currentPage={page} className="navOffset" />
          <Contact className="navOffset" />
        </ReactPageScroller>
        <div
          onClick={() => setPage(page + 1)}
          className={`col scrollDown ${!landingOpen || page === 2 ? 'hide' : ''}`}
        >
          <BsArrowDown />
          <BsArrowDown />
        </div>
      </Landing>
    </div>
  );
}

export default App;
