import React from 'react';
import './Landing.scss';

const Landing = (props: { children?: any; isPageOpen: boolean }) => {
  return (
    <div id='container'>
      <div id='landing' className={`${props.isPageOpen && 'open'}`}>
        <div className={`triangle left`}>
          <div className='titleSection'>
            <h1 className='name'>Brian Smith</h1>
            <h1 className='subtitle'>Film Production</h1>
          </div>
        </div>
        <div className={`triangle right`}>
          <div className='titleSection'>
            <h1 className='name'>Brian Smith</h1>
            <h1 className='subtitle'>Film Production</h1>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default Landing;
