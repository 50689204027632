import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import Youtube from '../Components/Youtube/Youtube';
import './Videos.scss';

const Videos = (props: { className?: string; currentPage: number }) => {
  const topVideoText = useAnimation();
  const middleVideoText = useAnimation();
  const bottomVideoText = useAnimation();

  useEffect(() => {
    if (props.currentPage === 0) {
      topVideoText.set({ opacity: 0, transform: 'transform: translateX(-50%);' });
      middleVideoText.set({ opacity: 0, transform: 'transform: translateY(50%);' });
      bottomVideoText.set({ opacity: 0, transform: 'transform: translateX(-50%);' });
    }
    if (props.currentPage === 1) {
      const translateBack = {
        opacity: 1,
        transform: 'translateX(0%)',
        transition: { duration: 1 },
      };

      setTimeout(() => topVideoText.start(translateBack), 1 * 1000);
      setTimeout(() => middleVideoText.start(translateBack), 2 * 1000);
      setTimeout(() => bottomVideoText.start(translateBack), 3 * 1000);
    }
  }, [topVideoText, middleVideoText, bottomVideoText, props.currentPage]);

  return (
    <div id='videos' className={`${props.className}`}>
      <Stack className='stack' gap={2}>
        <div className='videoRow'>
          <div className='text textLeft'>
            <h2>Video About Vans</h2>
            <motion.p animate={topVideoText}>Lorem ipsum dolor sit amet</motion.p>
          </div>
          <Youtube url='https://www.youtube.com/embed/rOm-kpavAPs' />
        </div>
        <div className='videoRow'>
          <Youtube url='https://www.youtube.com/embed/9pt69KZZbnU' />
          <div className='text textRight'>
            <h2>Crete Stuff Idk</h2>
            <motion.p animate={middleVideoText}>Lorem ipsum dolor sit amet, consecteta.</motion.p>
          </div>
        </div>
        <div className='videoRow bottomRow'>
          <div className='text textLeft'>
            <h2>Flight Fly Go Bye Bye</h2>
            <motion.p animate={bottomVideoText}>Lorem ipsum dolor sit amet, consectetur adia.</motion.p>
          </div>
          <Youtube url='https://www.youtube.com/embed/l-cLlOn0yu0' />
        </div>
      </Stack>
    </div>
  );
};
export default Videos;
